<template>
  <div
    class="d-flex justify-content-center align-items-center verify-register-email"
  >
    <div class="card" v-if="isShow">
      <div class="card__content text-center f-w3">
        <p>認証コードの有効期限が過ぎています。</p>
        <p>再申請してください。</p>
      </div>
      <div class="card-footer f-w6" @click="redirectTop()">
        OK
      </div>
    </div>
  </div>
</template>

<script>
import Cookies from "js-cookie";

export default {
  name: "VerifyRegisterEmail",
  data() {
    return {
      isShow: false
    };
  },
  metaInfo() {
    return {
      title: "登録メールを確認する",
      titleTemplate: "%s | PreGo"
    };
  },
  created() {
    var params = {
      token: this.$route.query.token
    };

    if (this.$route.query.invitation_code || Cookies.get("invitation_code")) {
      params.invitation_code =
        this.$route.query.invitation_code || Cookies.get("invitation_code");
    }

    this.$root.$refs.loading.start();
    this.$store
      .dispatch("register/verifyRegister", params)
      .then(() => {
        this.$root.$refs.loading.finish();
        this.$toast("アカウントの確認が成功しました", "通知", "success");
        if (Cookies.get("invitation_code")) {
          Cookies.remove("invitation_code");
        }
        localStorage.setItem("step", 1);
        localStorage.setItem("add-friend-line", 1);
        if (this.$route.query.sex == 1 || localStorage.getItem("sex") == 1) {
          location.replace("/my-page/profile/edit-profile");
        } else {
          location.replace("/cast/my-page/edit-profile");
        }
      })
      .catch(error => {
        if (error.response.data.error.code === "5022") {
          this.isShow = true;
        } else if (error.response.data.error.code === "5025") {
          if (
            this.$route.query.invitation_code ||
            Cookies.get("invitation_code")
          ) {
            this.$router.push({
              name: "TopRegister",
              query: {
                code:
                  this.$route.query.invitation_code ||
                  Cookies.get("invitation_code")
              }
            });
          } else {
            this.$router.push({ name: "TopRegister" });
          }
        }
        this.$root.$refs.loading.finish();
      });
  },
  methods: {
    redirectTop() {
      if (this.$route.query.invitation_code || Cookies.get("invitation_code")) {
        this.$router.push({
          name: "TopRegister",
          query: {
            code:
              this.$route.query.invitation_code ||
              Cookies.get("invitation_code")
          }
        });
      } else {
        this.$router.push({ name: "TopRegister" });
      }
    }
  }
};
</script>

<style lang="scss">
@import "@/assets/scss/_fontFamily";
@import "@/assets/scss/verifyRegiaterEmail";
</style>
